import { Component, Inject, OnInit } from '@angular/core';
import {
  DepartmentRepository,
  NoteCreateForBudgetRequestModel,
  NoteCreateForCustomerRequestModel,
  NoteCreateRequestModel,
  NoteEditRequestModel,
  NoteRepository,
  UserRepository,
} from '@garrampa/api-services/garrampa';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  map,
  Observable,
  startWith,
  throwError,
} from 'rxjs';
import { FormErrorsParserService } from '../../services/form-errors-parser.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { StringToNumberPipe } from '../../pipes/string-to-number.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  NgTemplateOutlet,
  AsyncPipe,
  KeyValuePipe,
  DatePipe,
} from '@angular/common';
import { ApiErrorResponseModel, EnumType } from '@garrampa/api-services';

interface NoteForm {
  id: FormControl<number | null>;
  departments: FormControl<number[] | null>;
  responsibleId: FormControl<number | null>;
  actionUserId: FormControl<number | null>;
  isImportant: FormControl<boolean>;
  dateAction: FormControl<Date | null | string>;
  note: FormControl<string | null>;
  answer: FormControl<string | null>;
  type: FormControl<string | null>;
}

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
  imports: [
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    AsyncPipe,
    KeyValuePipe,
    StringToNumberPipe,
  ],
  providers: [DatePipe],
})
export class NotesDialogComponent implements OnInit {
  // Department control
  availableDepartments: EnumType = {};
  departmentsFilterControl = new FormControl<string>('');
  filteredDepartments = new Observable<EnumType>();

  // Responsible agent and action user controls
  availableUsers: EnumType = {};
  responsibleAgentFilterControl = new FormControl<string>('');
  filteredResponsibleAgents = new Observable<EnumType>();
  actionUserFilterControl = new FormControl<string>('');
  filteredActionUsers = new Observable<EnumType>();

  origin: 'budget' | 'userNotes' | 'customer' | 'invoicing';
  // ID of note
  id?: number;
  // ID of origin (budget, etc)
  originId?: number;
  form: FormGroup<NoteForm>;

  today = new Date();
  canAnswer = false;
  noteType: 'task' | 'note' | 'message' | 'warning' = 'task';

  constructor(
    private departmentRepo: DepartmentRepository,
    private userRepo: UserRepository,
    private noteRepo: NoteRepository,
    private formErrorsParser: FormErrorsParserService,
    private snackbar: MatSnackBar,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    data: {
      origin: 'budget' | 'userNotes' | 'customer' | 'invoicing';
      id?: number;
      originId?: number;
    },
    private dialogRef: MatDialogRef<NotesDialogComponent>,
  ) {
    this.origin = data.origin;
    this.id = data.id;
    this.originId = data.originId;
    this.form = this.initForm();

    if (this.id) {
      this.loadNote();
    }

    this.filteredDepartments = this.departmentsFilterControl.valueChanges.pipe(
      startWith(null),
      debounceTime(100),
      map((department: string | null) =>
        department
          ? this.filterDepartments(department)
          : this.availableDepartments,
      ),
    );

    this.filteredResponsibleAgents =
      this.responsibleAgentFilterControl.valueChanges.pipe(
        startWith(null),
        debounceTime(100),
        map((user: string | null) =>
          user ? this.filterUsers(user) : this.availableUsers,
        ),
      );

    this.filteredActionUsers = this.actionUserFilterControl.valueChanges.pipe(
      startWith(null),
      debounceTime(100),
      map((user: string | null) =>
        user ? this.filterUsers(user) : this.availableUsers,
      ),
    );
  }

  ngOnInit(): void {
    this.loadDepartments();
    this.loadUsers();

    // Added for usage with native datepicker-local
    this.form.controls.dateAction.valueChanges.subscribe(value => {
      if (value instanceof Date) {
        this.form.controls.dateAction.setValue(
          this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss'),
        );
      }
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    switch (this.origin) {
      case 'budget':
        if (this.originId) {
          this.noteRepo
            .createForBudget(
              this.originId,
              this.form.getRawValue() as NoteCreateForBudgetRequestModel,
            )
            .pipe(
              catchError((err: ApiErrorResponseModel) => {
                this.formErrorsParser.parseErrorsIntoForm(this.form, err);

                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.snackbar.open('Guardado correctamente', 'Cerrar', {
                duration: 5000,
              });
              this.dialogRef.close(true);
            });
        }
        break;
      case 'invoicing':
        if (this.originId) {
          this.noteRepo
            .createForEconomic(
              this.originId,
              this.form.getRawValue() as NoteCreateForBudgetRequestModel,
            )
            .pipe(
              catchError((err: ApiErrorResponseModel) => {
                this.formErrorsParser.parseErrorsIntoForm(this.form, err);

                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.snackbar.open('Guardado correctamente', 'Cerrar', {
                duration: 5000,
              });
              this.dialogRef.close(true);
            });
        }
        break;
      case 'userNotes':
        if (this.id) {
          // EDIT
          this.noteRepo
            .edit(this.id, this.form.getRawValue() as NoteEditRequestModel)
            .pipe(
              catchError((err: ApiErrorResponseModel) => {
                this.formErrorsParser.parseErrorsIntoForm(this.form, err);

                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.snackbar.open('Guardado correctamente', 'Cerrar', {
                duration: 5000,
              });
              this.dialogRef.close(true);
            });
        } else {
          // Create
          this.noteRepo
            .create(this.form.getRawValue() as NoteCreateRequestModel)
            .pipe(
              catchError((err: ApiErrorResponseModel) => {
                this.formErrorsParser.parseErrorsIntoForm(this.form, err);

                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.snackbar.open('Guardado correctamente', 'Cerrar', {
                duration: 5000,
              });
              this.dialogRef.close(true);
            });
        }
        break;
      case 'customer':
        // Create
        if (this.originId) {
          this.noteRepo
            .createForCustomer(
              this.originId,
              this.form.getRawValue() as NoteCreateForCustomerRequestModel,
            )
            .pipe(
              catchError((err: ApiErrorResponseModel) => {
                this.formErrorsParser.parseErrorsIntoForm(this.form, err);

                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.snackbar.open('Guardado correctamente', 'Cerrar', {
                duration: 5000,
              });
              this.dialogRef.close(true);
            });
        }
        break;
    }
  }

  setDefaultDate(type: 'now' | 'tomorrow'): void {
    const today = DateTime.now();

    if (type === 'tomorrow') {
      const tomorrow = today.plus({ day: 1 });
      const tomorrowMorning = tomorrow.set({
        hour: 9,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      this.form.controls.dateAction.setValue(tomorrowMorning.toJSDate());
    } else {
      this.form.controls.dateAction.setValue(today.toJSDate());
    }
  }

  changeTab(newTab: number): void {
    this.form.reset();
    switch (newTab) {
      case 0:
        this.noteType = 'task';
        this.form.controls.dateAction.enable();
        this.form.controls.type.setValue('task');
        break;
      case 1:
        this.noteType = 'note';
        // Disable this control because it has required validator
        this.form.controls.dateAction.disable();
        this.form.controls.type.setValue('note');
        break;
      case 2:
        this.noteType = 'message';
        this.form.controls.dateAction.enable();
        this.form.controls.type.setValue('alert');
        this.form.controls.isImportant.setValue(false);
        break;
      case 3:
        this.noteType = 'warning';
        this.form.controls.dateAction.disable();
        this.form.controls.type.setValue('warning');
        this.form.controls.isImportant.setValue(true);
        break;
    }
  }

  private loadDepartments(): void {
    this.departmentRepo.getAllSelectValues({}).subscribe(data => {
      this.availableDepartments = data;
      this.departmentsFilterControl.setValue('');
    });
  }

  private loadUsers(): void {
    this.userRepo.getSelectValues().subscribe(data => {
      this.availableUsers = data;
      this.responsibleAgentFilterControl.setValue('');
      this.actionUserFilterControl.setValue('');
    });
  }

  private initForm(): FormGroup<NoteForm> {
    return new FormGroup<NoteForm>({
      id: new FormControl<number | null>(null),
      departments: new FormControl<number[] | null>(null),
      responsibleId: new FormControl<number | null>(null),
      actionUserId: new FormControl<number | null>(null),
      isImportant: new FormControl<boolean>(false, { nonNullable: true }),
      dateAction: new FormControl<Date | string | null>(null, {
        validators: [Validators.required],
      }),
      note: new FormControl<string | null>(null, {
        validators: [Validators.required],
      }),
      answer: new FormControl<string | null>(null),
      type: new FormControl<string | null>('task'),
    });
  }

  private loadNote(): void {
    if (this.id) {
      this.noteRepo.getSingle(this.id).subscribe(data => {
        this.form.patchValue(data);
        this.canAnswer = data.canAnswer;

        if (!data.canEdit) {
          this.blockEditFields();
        }
      });
    }
  }

  private filterDepartments(input: string): EnumType {
    return Object.keys(this.availableDepartments)
      .filter(key =>
        input
          ? this.availableDepartments[key as unknown as number]
              .toLowerCase()
              .includes(input.toString().toLowerCase())
          : this.availableDepartments,
      )
      .reduce((cur, key) => {
        return Object.assign(cur, {
          [key]: this.availableDepartments[key as unknown as number],
        });
      }, {});
  }

  private filterUsers(input: string): EnumType {
    return Object.keys(this.availableUsers)
      .filter(key =>
        input
          ? this.availableUsers[key as unknown as number]
              .toLowerCase()
              .includes(input.toString().toLowerCase())
          : this.availableUsers,
      )
      .reduce((cur, key) => {
        return Object.assign(cur, {
          [key]: this.availableUsers[key as unknown as number],
        });
      }, {});
  }

  private blockEditFields(): void {
    this.form.controls.id.disable();
    this.form.controls.note.disable();
    this.form.controls.dateAction.disable();
    this.form.controls.actionUserId.disable();
    this.form.controls.responsibleId.disable();
    this.form.controls.departments.disable();
    this.form.controls.isImportant.disable();
  }
}
